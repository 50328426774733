import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/introduction-to-webpack",
  "date": "2015-09-01",
  "title": "INTRODUCTION TO WEBPACK",
  "author": "admin",
  "tags": ["development", "javascript", "react.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Webpack is a module bundler. Webpack takes modules with dependencies and generates static assets representing those modules."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why Webpack?`}</h2>
    <p>{`With webpack you can easily split your application into multiple files/chunks.`}</p>
    <p>{`This way, your codebase can be split into multiple chunks and those chunks can be loaded on demand reducing the initial loading time of your application.`}</p>
    <Message type="info" title="Caching" content="Webpack can also make your chunks cache friendly by using `hashes`." mdxType="Message" />
    <p>{`It can build and bundle CSS, preprocessed CSS, compile-to-JS languages (like CoffeeScript), images and more by utilising webpack loaders.`}</p>
    <p>{`Another great feature is webpack `}<inlineCode parentName="p">{`plugins`}</inlineCode>{`. Webpack plugins have the ability to inject themselves into the build process to do all sorts of things.`}</p>
    <h2>{`Using Webpack`}</h2>
    <p>{`You can install Webpack through `}<inlineCode parentName="p">{`npm`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install webpack -g
`}</code></pre>
    <p>{`Add a `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file to the root of your projects directory with the following `}<strong parentName="p">{`npm init`}</strong>{` command or copy and save the snippet below as your package.json.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "name": "WebpackDemo",
  "author": "Your name",
  "version": "0.0.1",
  "description": "Trying out web pack",
  "private": true,
  "scripts": {},
  "dependencies": {},
  "devDependencies": {}
}
`}</code></pre>
    <p>{`Now we want to add webpack to the newly created package.json file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install webpack --save-dev
`}</code></pre>
    <Message type="warn" title="" content="to prevent your configuration accidently ending up in Github, you have to make sure the `private : true` flag is set in your package.json file." mdxType="Message" />
    <p>{`Now the webpack command is available via the CLI. You can find a detailed list of options on the official site or via `}<strong parentName="p">{`webpack -h`}</strong>{`.`}</p>
    <h2>{`Configuaration Options`}</h2>
    <table>
	<th>Option</th>
	<th>Description</th>
	<tr>
		<td>`webpack`</td><td>&nbsp;Building once for development</td>
	</tr>
	<tr>
		<td>`webpack -p`</td><td>&nbsp;Building once for production (minification)</td>
	</tr>
	<tr>
		<td>`webpack --watch`</td><td>&nbsp;Continuous incremental build</td>
	</tr>
	<tr>
		<td>`webpack -d`</td><td>&nbsp;Include source maps</td>
	</tr>
	<tr>
		<td>`webpack --colors`</td><td>&nbsp;Making things pretty</td>
	</tr>
    </table>
    <h2>{`Creating the Config File`}</h2>
    <p>{`To start we need to create the `}<strong parentName="p">{`webpack.config.js`}</strong>{` in the root of our projects directory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  entry: './main.js',
  output: {
    filename: 'bundle.js'
  }
};
`}</code></pre>
    <p>{`Create a simple JavaScript file called `}<strong parentName="p">{`main.js`}</strong>{` with the following contents:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`document.write("Hello Webpack!");
`}</code></pre>
    <p>{`Now by running the `}<strong parentName="p">{`webpack`}</strong>{` command via the CLI you should have a bundle.js file available. What happened is the webpack command found your `}<strong parentName="p">{`webpack.config.js`}</strong>{` file and worked its magic.`}</p>
    <p>{`To make sure everything is working as expected, we can create a simple `}<strong parentName="p">{`index.html`}</strong>{` file with the following content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
  <head>
    <script type="text/javascript" src="bundle.js"></script>
  </head>
  <body>
    <h1>Webpack Intro</h1>
  </body>
</html>
`}</code></pre>
    <h2>{`Adding Multiple Modules`}</h2>
    <p>{`Webpack supports adding multiple modules to your project.`}</p>
    <p>{`Create a new file called `}<strong parentName="p">{`extra.js`}</strong>{` with the following contents:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = document.write('Another file');
`}</code></pre>
    <p>{`Now make a reference to that file in your `}<strong parentName="p">{`main.js`}</strong>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require('./extra.js');
`}</code></pre>
    <p>{`Run the `}<strong parentName="p">{`webpack`}</strong>{` command from the CLI and open up your `}<strong parentName="p">{`bundle.js`}</strong>{` file. You should now see that both files are happily living together.`}</p>
    <Message type="info" title="Minification" content="If you want to minify the file, run **webpack -p**. Now your **bundle.js** is minified." mdxType="Message" />
    <h2>{`Adding loaders`}</h2>
    <p>{`If you want to use ES6 modules in your project, you can use `}<inlineCode parentName="p">{`Babel`}</inlineCode>{`. Babel is a compiler for writing next generation JavaScript.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{` npm install babel-loader --save-dev
`}</code></pre>
    <p>{`This will add `}<inlineCode parentName="p">{`babel-loader`}</inlineCode>{` to your `}<strong parentName="p">{`package.json`}</strong>{` file.`}</p>
    <p>{`Add this new section to your `}<strong parentName="p">{`webpack.config.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module: {
  loaders: [
    {
      test: /\\.jsx?$/,
      exclude: /(node_modules|bower_components)/,
      loader: 'babel?optional[]=runtime&stage=0'
    }
  ]
}
`}</code></pre>
    <p>{`To test the loader we will need to add a simple JavaScript with ES6 file. Lets call it another.js and add the following contents:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let me = 'be';
console.log(\`\${me} myself!\`);
`}</code></pre>
    <Message type="warn" title="Babel loader is slow!" content="Make sure you are transforming as few files as possible. Because you are probably matching **/\.js$/**, you might be transforming the **node_modules** folder or other unwanted source." mdxType="Message" />
    <p>{`Run the `}<inlineCode parentName="p">{`webpack`}</inlineCode>{` command on the CLI and you should now be seeing it in console.`}</p>
    <h2>{`Adding CSS and images`}</h2>
    <p>{`First we will need to add a couple more loaders to our `}<strong parentName="p">{`package.json`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install style-loader css-loader --save-dev
`}</code></pre>
    <p>{`Add this new section to your `}<strong parentName="p">{`webpack.config.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{ test: /\\.css$/, loader: 'style-loader!css-loader' }
`}</code></pre>
    <p>{`Inside the `}<strong parentName="p">{`webpack.config.js`}</strong>{` adjust the output parameter to look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`output: {
  path: './build',
  publicPath: 'http://yoururl.com/',
  filename: 'bundle.js'
}
`}</code></pre>
    <p>{`Also let's create a new CSS file called `}<strong parentName="p">{`styles.css`}</strong>{` with the following contents:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`body {
  background-color: beige;
}
`}</code></pre>
    <p>{`Require that (`}<strong parentName="p">{`require("./styles.css");`}</strong>{`) in the `}<strong parentName="p">{`main.js`}</strong>{`.`}</p>
    <p>{`Re-run `}<strong parentName="p">{`webpack`}</strong>{` from the command line and voilà !`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      